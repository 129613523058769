export const lookupTable = {
  Fuji: {
    35: 0.0,
    36: 0.003,
    37: 0.006,
    38: 0.009,
    39: 0.012,
    40: 0.015,
    41: 0.018,
    42: 0.021,
    43: 0.024,
    44: 0.027,
    45: 0.03,
    46: 0.0377,
    47: 0.0454,
    48: 0.0531,
    49: 0.0608,
    50: 0.0685,
    51: 0.0762,
    52: 0.0839,
    53: 0.0916,
    54: 0.0993,
    55: 0.107,
    56: 0.1148,
    57: 0.1225,
    58: 0.1303,
    59: 0.138,
    60: 0.1458,
    61: 0.1535,
    62: 0.1613,
    63: 0.169,
    64: 0.1768,
    65: 0.1845,
    66: 0.1881,
    67: 0.1917,
    68: 0.1953,
    69: 0.1989,
    70: 0.2025,
    71: 0.2061,
    72: 0.2097,
    73: 0.2133,
    74: 0.2169,
    75: 0.2205,
    76: 0.22055,
    77: 0.2206,
    78: 0.22065,
    79: 0.22068,
    80: 0.2207,
    81: 0.22075,
    82: 0.2208,
    83: 0.2209,
    84: 0.221,
    85: 0.2211,
    86: 0.2169,
    87: 0.2127,
    88: 0.2085,
    89: 0.2043,
    90: 0.2001,
    91: 0.1958,
    92: 0.1916,
    93: 0.1874,
    94: 0.1832,
    95: 0.179,
    96: 0.1611,
    97: 0.1432,
    98: 0.1253,
    99: 0.1074,
    100: 0.0895,
    101: 0.0716,
    102: 0.0537,
    103: 0.0358,
    104: 0.0179,
    105: 0.0,
  },
  Gala: {
    35: 0,
    36: 0.00626,
    37: 0.0125,
    38: 0.0188,
    39: 0.0254,
    40: 0.0313,
    41: 0.0376,
    42: 0.0438,
    43: 0.05,
    44: 0.0563,
    45: 0.0626,
    46: 0.0648,
    47: 0.0667,
    48: 0.0691,
    49: 0.0712,
    50: 0.0734,
    51: 0.0755,
    52: 0.0777,
    53: 0.0798,
    54: 0.082,
    55: 0.0841,
    56: 0.0932,
    57: 0.1023,
    58: 0.1113,
    59: 0.1205,
    60: 0.1296,
    61: 0.1386,
    62: 0.1477,
    63: 0.1568,
    64: 0.1659,
    65: 0.175,
    66: 0.1845,
    67: 0.194,
    68: 0.2035,
    69: 0.213,
    70: 0.2225,
    71: 0.232,
    72: 0.2415,
    73: 0.251,
    74: 0.2605,
    75: 0.27,
    76: 0.28583,
    77: 0.30166,
    78: 0.31749,
    79: 0.33332,
    80: 0.34915,
    81: 0.36498,
    82: 0.38081,
    83: 0.39664,
    84: 0.41247,
    85: 0.4283,
    86: 0.42064,
    87: 0.41298,
    88: 0.40532,
    89: 0.39966,
    90: 0.39,
    91: 0.38234,
    92: 0.37468,
    93: 0.36702,
    94: 0.35936,
    95: 0.3517,
    96: 0.3165,
    97: 0.2814,
    98: 0.2462,
    99: 0.211,
    100: 0.1759,
    101: 0.1407,
    102: 0.1055,
    103: 0.0703,
    104: 0.0352,
    105: 0.0,
  },
  "Golden Delicious": {
    35: 0.0,
    36: 0.0049,
    37: 0.0098,
    38: 0.0147,
    39: 0.0196,
    40: 0.0246,
    41: 0.0295,
    42: 0.0344,
    43: 0.0393,
    44: 0.0442,
    45: 0.0491,
    46: 0.0523,
    47: 0.0554,
    48: 0.0586,
    49: 0.0617,
    50: 0.0649,
    51: 0.0681,
    52: 0.0712,
    53: 0.0744,
    54: 0.0775,
    55: 0.0807,
    56: 0.0885,
    57: 0.0962,
    58: 0.104,
    59: 0.1117,
    60: 0.1195,
    61: 0.1273,
    62: 0.135,
    63: 0.1428,
    64: 0.1505,
    65: 0.1583,
    66: 0.1683,
    67: 0.1783,
    68: 0.1883,
    69: 0.1983,
    70: 0.2083,
    71: 0.2183,
    72: 0.2283,
    73: 0.2383,
    74: 0.2483,
    75: 0.2583,
    76: 0.2654,
    77: 0.2725,
    78: 0.2796,
    79: 0.2867,
    80: 0.2938,
    81: 0.3009,
    82: 0.308,
    83: 0.3151,
    84: 0.3222,
    85: 0.3293,
    86: 0.3092,
    87: 0.2891,
    88: 0.269,
    89: 0.2489,
    90: 0.2288,
    91: 0.2087,
    92: 0.1886,
    93: 0.1685,
    94: 0.1484,
    95: 0.1283,
    96: 0.1155,
    97: 0.1027,
    98: 0.0898,
    99: 0.077,
    100: 0.0642,
    101: 0.0513,
    102: 0.0385,
    103: 0.0257,
    104: 0.0128,
    105: 0.0,
  },
  "Granny Smith": {
    35: 0,
    36: 0.0063,
    37: 0.0125,
    38: 0.0188,
    39: 0.025,
    40: 0.0313,
    41: 0.0376,
    42: 0.0438,
    43: 0.0501,
    44: 0.0563,
    45: 0.0626,
    46: 0.0641,
    47: 0.0656,
    48: 0.067,
    49: 0.0685,
    50: 0.07,
    51: 0.0715,
    52: 0.0729,
    53: 0.0744,
    54: 0.0759,
    55: 0.0774,
    56: 0.0871,
    57: 0.0969,
    58: 0.1066,
    59: 0.1164,
    60: 0.1262,
    61: 0.1359,
    62: 0.1457,
    63: 0.1555,
    64: 0.1652,
    65: 0.175,
    66: 0.1803,
    67: 0.1856,
    68: 0.1909,
    69: 0.1962,
    70: 0.2015,
    71: 0.2068,
    72: 0.2121,
    73: 0.2174,
    74: 0.2227,
    75: 0.228,
    76: 0.2406,
    77: 0.2532,
    78: 0.2658,
    79: 0.2784,
    80: 0.291,
    81: 0.3036,
    82: 0.3162,
    83: 0.3288,
    84: 0.3414,
    85: 0.354,
    86: 0.3473,
    87: 0.3406,
    88: 0.3339,
    89: 0.3272,
    90: 0.3205,
    91: 0.3138,
    92: 0.3071,
    93: 0.3004,
    94: 0.2937,
    95: 0.287,
    96: 0.2583,
    97: 0.2296,
    98: 0.2009,
    99: 0.1722,
    100: 0.1435,
    101: 0.1148,
    102: 0.0861,
    103: 0.0574,
    104: 0.0287,
    105: 0.0,
  },
  Honeycrisp: {
    35: 0,
    36: 0.0045,
    37: 0.009,
    38: 0.0135,
    39: 0.018,
    40: 0.0225,
    41: 0.027,
    42: 0.0315,
    43: 0.036,
    44: 0.0405,
    45: 0.045,
    46: 0.0463,
    47: 0.0476,
    48: 0.0489,
    49: 0.0502,
    50: 0.0515,
    51: 0.0528,
    52: 0.0541,
    53: 0.0554,
    54: 0.0567,
    55: 0.058,
    56: 0.0721,
    57: 0.0862,
    58: 0.1003,
    59: 0.1144,
    60: 0.1285,
    61: 0.1426,
    62: 0.1567,
    63: 0.1708,
    64: 0.1849,
    65: 0.199,
    66: 0.2069,
    67: 0.2148,
    68: 0.2227,
    69: 0.2306,
    70: 0.2385,
    71: 0.2464,
    72: 0.2543,
    73: 0.2622,
    74: 0.2701,
    75: 0.278,
    76: 0.2881,
    77: 0.2982,
    78: 0.3083,
    79: 0.3184,
    80: 0.3285,
    81: 0.3386,
    82: 0.3487,
    83: 0.3588,
    84: 0.3689,
    85: 0.379,
    86: 0.3447,
    87: 0.3014,
    88: 0.2761,
    89: 0.2418,
    90: 0.2075,
    91: 0.1732,
    92: 0.1389,
    93: 0.1046,
    94: 0.0703,
    95: 0.036,
    96: 0.0324,
    97: 0.0288,
    98: 0.025,
    99: 0.0216,
    100: 0.018,
    101: 0.0144,
    102: 0.0108,
    103: 0.0072,
    104: 0.0036,
    105: 0.0,
  },
  "Red Delicious": {
    35: 0.0,
    36: 0.0041,
    37: 0.0082,
    38: 0.0123,
    39: 0.0164,
    40: 0.0205,
    41: 0.0246,
    42: 0.0287,
    43: 0.0328,
    44: 0.0369,
    45: 0.041,
    46: 0.0457,
    47: 0.0503,
    48: 0.055,
    49: 0.0596,
    50: 0.0643,
    51: 0.0689,
    52: 0.0736,
    53: 0.0782,
    54: 0.0829,
    55: 0.0875,
    56: 0.09,
    57: 0.0924,
    58: 0.0949,
    59: 0.0973,
    60: 0.0998,
    61: 0.1022,
    62: 0.1047,
    63: 0.1071,
    64: 0.1096,
    65: 0.112,
    66: 0.1122,
    67: 0.1124,
    68: 0.1126,
    69: 0.1128,
    70: 0.1129,
    71: 0.1131,
    72: 0.1133,
    73: 0.1135,
    74: 0.1137,
    75: 0.1138,
    76: 0.1145,
    77: 0.1152,
    78: 0.1159,
    79: 0.1166,
    80: 0.1172,
    81: 0.1179,
    82: 0.1186,
    83: 0.1193,
    84: 0.12,
    85: 0.1207,
    86: 0.1167,
    87: 0.1128,
    88: 0.1088,
    89: 0.1049,
    90: 0.1009,
    91: 0.0969,
    92: 0.093,
    93: 0.089,
    94: 0.0851,
    95: 0.0811,
    96: 0.073,
    97: 0.0649,
    98: 0.0568,
    99: 0.0487,
    100: 0.0406,
    101: 0.0324,
    102: 0.0243,
    103: 0.0162,
    104: 0.0081,
    105: 0.0,
  },
  "Pink Lady": {
    35: 0,
    36: 0.0048,
    37: 0.0095,
    38: 0.0143,
    39: 0.019,
    40: 0.0238,
    41: 0.0285,
    42: 0.0333,
    43: 0.038,
    44: 0.0428,
    45: 0.0475,
    46: 0.0502,
    47: 0.0529,
    48: 0.0556,
    49: 0.0583,
    50: 0.061,
    51: 0.0637,
    52: 0.0664,
    53: 0.0691,
    54: 0.0718,
    55: 0.0745,
    56: 0.0792,
    57: 0.0838,
    58: 0.0885,
    59: 0.0931,
    60: 0.0978,
    61: 0.1024,
    62: 0.1071,
    63: 0.1117,
    64: 0.1164,
    65: 0.121,
    66: 0.1271,
    67: 0.1332,
    68: 0.1393,
    69: 0.1454,
    70: 0.1515,
    71: 0.1576,
    72: 0.1637,
    73: 0.1698,
    74: 0.1759,
    75: 0.182,
    76: 0.1883,
    77: 0.1946,
    78: 0.2009,
    79: 0.2072,
    80: 0.2135,
    81: 0.2198,
    82: 0.2261,
    83: 0.2324,
    84: 0.2387,
    85: 0.245,
    86: 0.2313,
    87: 0.2176,
    88: 0.2039,
    89: 0.1902,
    90: 0.1765,
    91: 0.1628,
    92: 0.1491,
    93: 0.1354,
    94: 0.1217,
    95: 0.108,
    96: 0.0972,
    97: 0.0864,
    98: 0.0756,
    99: 0.0648,
    100: 0.054,
    101: 0.0432,
    102: 0.0324,
    103: 0.0216,
    104: 0.0108,
    105: 0.0,
  },
}
