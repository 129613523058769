import { lookupTable } from './_lookupTable'
import { format } from 'date-fns'

export default function PTGMLogic(isModelInSeason, hourlyData, state) {
  const {
    appleVariety,
    avgStyleLength,
    startDate,
    firstSpray,
    secondSpray,
    thirdSpray,
  } = state
  // console.log({ startDate, firstSpray, secondSpray, thirdSpray })
  let startDateIdx = 0
  let firstSprayIdx = startDateIdx + 1
  let secondSprayIdx = firstSprayIdx + 1
  let thirdSprayIdx = secondSprayIdx + 1

  if (startDate) {
    const idxSD = hourlyData
      .slice(startDateIdx)
      .findIndex(
        (d) => parseInt(d.ms / 1000) === parseInt(startDate.getTime() / 1000),
      )
    if (idxSD !== -1) startDateIdx = idxSD
  }

  if (firstSpray) {
    const idx1 = hourlyData
      .slice(startDateIdx)
      .findIndex(
        (d) => parseInt(d.ms / 1000) === parseInt(firstSpray.getTime() / 1000),
      )
    if (idx1 !== -1) firstSprayIdx = idx1
  }

  if (secondSpray) {
    const idx2 = hourlyData
      .slice(startDateIdx)
      .findIndex(
        (d) => parseInt(d.ms / 1000) === parseInt(secondSpray.getTime() / 1000),
      )
    if (idx2 !== -1) secondSprayIdx = idx2
  }

  if (thirdSpray) {
    const idx3 = hourlyData
      .slice(startDateIdx)
      .findIndex(
        (d) => parseInt(d.ms / 1000) === parseInt(thirdSpray.getTime() / 1000),
      )
    if (idx3 !== -1) thirdSprayIdx = idx3
  }

  let growthAcc = 0
  let firstSprayAcc = 0
  let secondSprayAcc = 0
  let thirdSprayAcc = 0

  let p = {
    hrGrowth: 0,
    cumGrowth: 0,
    percentageOfTarget: 0,
  }

  let mData = null
  if (isModelInSeason) {
    mData = hourlyData.slice(startDateIdx).map((d, i) => {
      if (d.temp === 'M') {
        p = {
          ...d,
          hrGrowth: 'N/A',
          cumGrowth: 'N/A',
          percentageOfTarget: 'N/A',
        }
      } else {
        p = { ...d }
        const temp = Math.round(d.temp)
        let hrGrowth = lookupTable[appleVariety.label][temp]
        if (typeof hrGrowth === 'undefined') hrGrowth = 0

        p['hrGrowth'] = hrGrowth
        growthAcc += hrGrowth

        // start Date ----------------------------------------------------
        p['cumGrowth'] = growthAcc
        if (avgStyleLength) {
          p['percentageOfTarget'] = (growthAcc / avgStyleLength) * 100
        }
        ///////////////////////////////////////////////////////////////////

        // First Spray ----------------------------------------------------
        if (firstSpray && avgStyleLength && i >= firstSprayIdx) {
          firstSprayAcc += hrGrowth
          p['cumGrowth'] = firstSprayAcc
          p['percentageOfTarget'] = (firstSprayAcc / avgStyleLength) * 100
        }
        ///////////////////////////////////////////////////////////////////

        // Second Spray ----------------------------------------------------
        if (secondSpray && avgStyleLength && i >= secondSprayIdx) {
          secondSprayAcc += hrGrowth
          p['cumGrowth'] = secondSprayAcc
          p['percentageOfTarget'] = (secondSprayAcc / avgStyleLength) * 100
        }
        ///////////////////////////////////////////////////////////////////

        // First Spray ----------------------------------------------------
        if (thirdSpray && avgStyleLength && i >= thirdSprayIdx) {
          thirdSprayAcc += hrGrowth
          p['cumGrowth'] = thirdSprayAcc
          p['percentageOfTarget'] = (thirdSprayAcc / avgStyleLength) * 100
        }
        ///////////////////////////////////////////////////////////////////
      }

      return p
    })
  }
  // console.log(mData)
  // Data to display in Results Table --------------------------------------------------
  let tableData = null
  if (isModelInSeason) tableData = mData.slice().reverse() // -10

  // //////////////////////////////////////////////////////////////////////////////////////

  // Data in CSV format to export ------------------------------------------------------
  let csvData = null
  if (isModelInSeason) {
    csvData = mData.map((h) => {
      if (h.temp !== 'M') {
        return {
          Date: format(h.date, 'yyyy-MM-dd HH:00'),
          'Air Temp (˚F)': Math.round(h.temp),
          'Hourly Growth (mm)': +h.hrGrowth.toFixed(2),
          'Cumulative Growth (mm)': +h.cumGrowth.toFixed(2),
          '% of Target': Math.round(h.percentageOfTarget),
        }
      }
      return {
        Date: format(h.date, 'yyyy-MM-dd HH:00'),
        'Air Temp (˚F)': h.temp,
        'Hourly Growth (mm)': 'N/A',
        'Cumulative Growth (mm)': 'N/A',
        '% of Target': 'N/A',
      }
    })
  }
  // console.log(csvData)
  //////////////////////////////////////////////////////////////////////////////////////

  let graphData = null
  const dataLength = mData ? mData.length : 0
  const currentHour = new Date().getHours()
  const includedHours = [0, 4, 8, 12, 16, 20, currentHour]

  if (isModelInSeason) {
    if (dataLength < 1000) {
      graphData = mData.map((d) => {
        return {
          date: d.date,
          ms: d.ms,
          formattedDate: format(d.date, 'MM-dd HH:00'),
          percentageOfTarget: d.percentageOfTarget,
          temp: d.temp,
        }
      })
    } else {
      graphData = mData
        .map((d, i) => {
          const hour = d.date.getHours()
          if (
            includedHours.includes(hour) ||
            i === startDateIdx ||
            i === firstSprayIdx ||
            i === secondSprayIdx ||
            i === thirdSprayIdx
          ) {
            return {
              date: d.date,
              ms: d.ms,
              formattedDate: format(d.date, 'MM-dd HH:00'),
              percentageOfTarget: d.percentageOfTarget,
              temp: d.temp,
            }
          }
          return null
        })
        .filter(Boolean)
    }
  }

  return {
    mData,
    tableData,
    csvData,
    graphData,
  }
}
